export const sidebarMenu = [
  {
    item_type: 'Label',
    title_en: 'Configurations',
    view_groups: null
  },
  {
    item_type: 'Link',
    title_en: 'Building Manager',
    icon: 'fas fa-utensils',
    view_groups: 'everyone',
    route: '/'
  }
]

export const profileMenuItems = [
  {
    type: 'Link',
    text_en: 'Change Password',
    icon: ' fa fa-regular fa-user ',
    link: '/'
  },
  {
    type: 'Divider',
    text_en: ''
  },
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => {
      console.log('Log out')
    }
  },
  {
    type: 'Action',
    text_en: 'Return to Digital Workflow Hub',
    icon: 'fa fa-power-off',
    action: () => {}
  }
]
