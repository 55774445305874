import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AdminPortalLayout, SBFloorPlan } from '@digitalworkflow/sbpcommon'
import { profileMenuItems, sidebarMenu } from './constant'

import logo from './assets/images/bgis-logo.png'
import logoSmImage from './assets/images/bgis-logo-sm.svg'

import '@digitalworkflow/sbpcommon/dist/index.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <AdminPortalLayout
              menuItems={sidebarMenu}
              profileMenuItems={profileMenuItems}
              logoImage={logo}
              logoSmImage={logoSmImage}
              username={'Imran Haider'}
            />
          }
        >
          <Route path='' element={<SBFloorPlan />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
